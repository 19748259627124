<div class="maincontentarea mt20">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="_contact_content">
                    <h2>Contest</h2>
                </div>
                <ul class="contest"
                    *ngIf="allContests?.length > 0">
                    <li *ngFor="let contest of allContests">
                        <div class="contest-img">
                            <a href="/contest-detail/{{contest.contest_id}}"><img [src]="contest.image_cloudfront_url" /></a>
                        </div>
                        <div class="contest-info">
                            <h3>{{ contest.name }}</h3>
                            <p>{{ contest.synopsis }}</p>
                            <div class="contest-info-btn d-flex align-items-center">
                                <div>
                                    <button routerLink="/contest-detail/{{contest.contest_id}}" class="default-btn-view mr-4">View Details</button>
                                    <!-- <button class="default-btn-view mr-4">Get Premium</button> -->
                                    <!-- <button class="default-btn-applied mr-4">Already Applied</button> -->
                                </div>
                                <div class="text-light">
                                    <span>Valid till {{ contest.valid_till }}</span><br/>
                                    <!-- <span>A subscription is required to participate</span> -->
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="no-contest text-center"
                    *ngIf="allContests?.length === 0">
                    <img src="../../../assets/img/Icon/no-contest.svg" />
                    <p>No contests available at the moment. Stay tuned!</p>
                </div>
            </div>
        </div>
    </div>
</div>