import { Component, OnInit } from '@angular/core';
import { ContestService } from 'src/app/core/services/contest.service';

@Component({
  selector: 'app-contest-list',
  templateUrl: './contest-list.component.html',
  styleUrls: ['./contest-list.component.css']
})
export class ContestListComponent implements OnInit {

  allContests: any;

  constructor(
    private contestService: ContestService,
  ) { }

  ngOnInit(): void {
    this.contestService.getAllContests().subscribe(
      (contestRes) => {
        console.log('contestRes: ', contestRes);
        this.allContests = contestRes.result;
      }, (error) => {
        console.error('error fetching contests: ', error);
      }
    );
  }

}
