<div class="maincontentarea mt20">
    <div class="container">
        <div class="row"
            *ngIf="contestData">
            <div class="col-sm-12">
                <div class="_contact_content">
                    <h2>Contest</h2>
                </div>
                <ul class="contest contest-detail">
                    <li>
                        <div class="contest-img ">
                            <img [src]="contestData.image_cloudfront_url" />
                        </div>
                        <div class="contest-info">
                            <h3>{{ contestData.name }}</h3>
                            <p>{{ contestData.synopsis }}</p>
                            <div class="contest-info-btn d-flex align-items-center">
                                <div *ngIf="contestData.content_availability=='free' || (contestData.content_availability=='paid' && contestData.is_pack_active == '1')">
                                    <button *ngIf="contestData.is_applied == '0'" routerLink="/contest-form/{{ contestData?.contest_id }}" class="default-btn-view mr-4">
                                        Participate Now
                                    </button>
                                    <span *ngIf="contestData.is_applied == '1'" class="default-btn-applied mr-4">
                                        Already Applied
                                    </span>
                                </div>
                                <div *ngIf="contestData.content_availability=='paid' && contestData.is_pack_active == '0'">
                                    <button routerLink="/subscribe/chooseplan" class="default-btn-view mr-4">
                                        Get Premium
                                    </button>
                                </div>
                                <div class="text-light">
                                    <span>Valid till {{ contestData.valid_till }}</span><br/>
                                    <span *ngIf="contestData.content_availability=='paid' && contestData.is_pack_active == '0'">
                                        A subscription is required to participate.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-md-12">
                <ul *ngIf="contestData?.tab?.length" class="nav nav-tabs" id="contestTab" role="tablist">
                    <li class="nav-item" *ngFor="let tab of contestData?.tab; let i = index">
                        <a class="nav-link" [class.active]="i === 0" data-toggle="tab" href="#tab-pane-{{i}}" role="tab" aria-controls="participate" [attr.aria-selected]="i === 0 ? 'true' : 'false'">
                            {{ tab.name }}
                        </a>
                    </li>
                      
                    <!-- <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#prizes" role="tab" aria-controls="prizes"aria-selected="true">PRIZES</a>
                    </li> -->
                </ul>
                <div class="tab-content contestTab-info" id="">
                    <div class="tab-pane fade show" [class.active]="i === 0" id="tab-pane-{{i}}" role="tabpanel" aria-labelledby="participate"
                        *ngFor="let tab of contestData?.tab; let i=index">
                        <p>{{ tab.synopsis }}</p>
                        <ul>
                            <li *ngFor="let point of tab?.points">{{ point }}</li>
                            <!-- <li>Why do we use it?</li>
                            <li>Where does it come from?</li>
                            <li>Where can I get some?</li> -->
                        </ul>
                    </div>
                    <!-- <div class="tab-pane fade" id="prizes" role="tabpanel" aria-labelledby="prizes">
                        <p>prizes Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <ul>
                            <li>What is Lorem Ipsum?</li>
                            <li>Why do we use it?</li>
                            <li>Where does it come from?</li>
                            <li>Where can I get some?</li>
                        </ul>
                    </div> -->
                </div>

                <p class="file-info">By submitting, you agree to our <a target="_blank" href="/tnc">Terms & Conditions.</a></p>
            </div>
        </div>
    </div>
</div>