import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContestService } from 'src/app/core/services/contest.service';

@Component({
  selector: 'app-contest-detail',
  templateUrl: './contest-detail.component.html',
  styleUrls: ['./contest-detail.component.css']
})
export class ContestDetailComponent implements OnInit {

  contestId: string;
  contestData: any;

  constructor(
    private route: ActivatedRoute,
    private contestService: ContestService,
  ) { }

  ngOnInit(): void {
    this.contestId = this.route.snapshot.paramMap.get('contestId') || '';

    this.contestService.getContestDetail(this.contestId).subscribe(
      (contestRes) => {
        console.log('contestRes: ', contestRes);
        this.contestData = contestRes.result;
      },
      (error) => {
        console.error('error fetching contest detail: ', error);
      }
    );
  }

}
